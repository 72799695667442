#camera {
  /*min-width: 100vw;
  min-height: 100vh;*/
  width: 100vw;
  height: 100vh;
  max-width: none;
  position: fixed;
  object-fit: cover;
  top: 0;
}

.wrapper {
  --overlayColor: #dbddd1;
  display: flex;
  justify-content: center;
  margin: 0 !important;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.overlay .closeButton {
  fill: var(--overlayColor);
  stroke: var(--overlayColor);
  top: 5vw;
  right: 5vw;
  width: 10vw;
  height: 10vw;
  position: fixed;
}

.overlay .crosshairs{
  --overlayWidth: 65vw;
  --overlayHeight: 65vw;
  position: fixed;
  top: calc(50% - (var(--overlayWidth) / 2) );
  left: calc(50% - (var(--overlayHeight) / 2) );
  width: calc(var(--overlayWidth));
  height: calc(var(--overlayHeight));
}

.overlay .crosshairs > div {
  --size: 10vw;
  --borderWidth: 4px;
  width: var(--size);
  height: var(--size);
  position: absolute;
  border-radius: 5px;;
}

.overlay #one {
  border-left: var(--borderWidth) solid var(--overlayColor);
  border-top: var(--borderWidth) solid var(--overlayColor);
}

.overlay #two {
  left: calc(var(--overlayWidth) - var(--size) );
  border-right: var(--borderWidth) solid var(--overlayColor);
  border-top: var(--borderWidth) solid var(--overlayColor);
}

.overlay #three {
  left: calc(var(--overlayWidth) - var(--size) );
  top: calc(var(--overlayHeight) - var(--size) );
  border-right: var(--borderWidth) solid var(--overlayColor);
  border-bottom: var(--borderWidth) solid var(--overlayColor);
}

.overlay #four {
  top: calc(var(--overlayHeight) - var(--size) );
  border-left: var(--borderWidth) solid var(--overlayColor);
  border-bottom: var(--borderWidth) solid var(--overlayColor);
}